<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <label> {{ $t('admin.duplicate.calendar.name') }}</label>
            <i @click="onCopy('name')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
                <d-text-field
                  v-model="calendar.name"
                  error-text="validation.text-input-three-characters"
                  class="btn-full-width"
                  class-name="bg-white"
                  size="sm"
                />
            </span>
          </b-col>
          <b-col class="mt-2" cols="12">
            <label class="mt-1 mr-2"> Date début </label>
            <i @click="onCopy('fromDate')" class="pointer ml-2 fa-15x" :class="icon"></i>
            <d-inline-calendar
              :label="calendar.fromDate ? $moment(calendar.fromDate).tz('Europe/Paris').format('DD/MM/YYYY') : $t('general.actions.undefined')"
              class-name="width-auto w-100"
              identifier="fromDate"
              @on:select="setDate('fromDate', $event)"
            />
          </b-col>

          <b-col class="mt-2" cols="12">
            <label class="mt-1 mr-2"> Date de fin </label>
            <i @click="onCopy('toDate')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <d-inline-calendar
              :label="calendar.toDate ? $moment(calendar.toDate).tz('Europe/Paris').format('DD/MM/YYYY') : $t('general.actions.undefined')"
              class-name="width-auto w-100"
              identifier="toDate"
              @on:select="setDate('toDate', $event)"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col class="ml-1" cols="12">
            {{ $t('admin.duplicate.calendar.available-days') }}
            <i @click="onCopy('daysOfTheWeek')" class="pointer ml-2 fa-15x" :class="icon"></i>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="1"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.monday') }}
            </vs-checkbox>
          </b-col>

          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="2"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.tuesday') }}
            </vs-checkbox>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="3"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.wednesday') }}
            </vs-checkbox>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="4"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.thursdays') }}
            </vs-checkbox>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="5"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.friday') }}
            </vs-checkbox>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="6"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.saturday') }}
            </vs-checkbox>
          </b-col>
          <b-col cols="12" class="mt-2 d-flex">
            <vs-checkbox
              v-model="calendar.daysOfTheWeek"
              vs-name="dates"
              vs-value="0"
              color="primary"
            >
              {{ $t('admin.duplicate.calendar.week.sunday') }}
            </vs-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="hasBorder" class="border-bottom-black mt-3 mb-4"/>

  </div>
</template>
<script>
export default {
  props: {
    calendar: {
      type: Object,
      default: this
    },
    icon: {
      type: String,
      default: 'icofont icofont-download-alt'
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setDate(date, $event) {
      this.calendar[date] = this.$moment($event);
      console.log(this.calendar[date].format('DD-MM-YYYY HH:mm'), $event);
    },
    onCopy(key) {
      const payload = {};
      payload[key] = this.calendar[key];

      this.$store.commit('calendar/updateCalendarCheckedList', payload)
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}
</style>
